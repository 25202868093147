@import './mixins.scss';

@keyframes animate-view {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

html,
body {
	scroll-behavior: smooth;
	height: 100%;
}

button {
	color: var(--background-color);
	background-color: var(--text-highlight-color);
	border: 1px solid var(--text-color);
	border-radius: 0.25em;

	font-family: inherit;
	font-size: inherit;

	padding: 0.5em 2em;
	margin: 0 auto;
	display: block;
	font-weight: bold;

	cursor: pointer;
}

ul {
	list-style-type: circle;
	margin: 0;
	padding: 0;
	li {
		margin: 0.25em 0 0.25em 1em;
	}
}

.blank-button {
	@include blank-button;
}
