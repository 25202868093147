@import '../../styles/global.scss';

.Home {
	padding-top: 1em;

	h4 {
		margin: 1em 0;
	}

	.me {
		float: right;
		width: 200px;
		height: 200px;
		border-radius: 25%;
		object-fit: contain;
		margin-left: 0.5em;
		@include standard-box-shadow;
	}
}
