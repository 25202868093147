.Projects {
	.Card {
		.technologies {
			display: inline-flex;
			flex-wrap: wrap;
			gap: 0.7em;
			align-items: center;
			margin: 1em 0;
			.technology {
				display: flex;
				align-items: center;
				border-radius: 1em;
				padding: 0.3em 0.8em 0.4em 0.8em;
				background-color: var(--text-highlight-color);
				color: var(--background-highlight-color);
				line-height: 1;
			}
		}
	}
}
