@import '../../styles/global.scss';

.Menu {
	position: absolute;
	top: 0;

	&:hover {
		ul li {
			a {
				svg {
					transform: scale(0.7);
					margin-top: 0;
				}
				span {
					opacity: 1;
				}
			}
		}
	}

	ul {
		display: flex;
		background: linear-gradient(to right, var(--background-color), transparent);
		flex-direction: column;
		list-style-type: none;
		margin: 0 0 0 0.5em;
		padding: 0;

		li {
			display: flex;
			padding: 0;
			margin: 0 0 1em 0;
			text-align: center;
			a {
				align-items: center;
				border-color: var(--text-highlight-color);
				color: var(--text-highlight-color);
				font-weight: 700;
				padding: 1em 0;
				position: relative;
				text-decoration: none;
				width: 100%;

				&,
				&:active,
				&:visited {
					color: var(--text-color);
					display: block;
					margin-top: -0.75rem;
				}
				&:hover span:after {
					content: '';
					border-bottom: 2px solid var(--text-color);
					display: block;
					width: 100%;
				}

				span {
					opacity: 0;
					transition: opacity 0.75s;
					background-color: inherit;
					display: inline-block;
					&:after {
						border-bottom: 2px solid transparent;
						content: '';
						display: block;
						left: 1px;
						margin: 0 auto;
						position: relative;
						transition: width 0.2s ease-out;
						width: 0;
					}
				}
				svg {
					display: block;
					position: relative;
					top: 0.25em;
					transform-origin: 50% 50%;
					transform: scale(1);
					transition: transform 0.2s ease-in-out;
					width: 40px;
					height: 40px;
					margin: 0 auto;
				}
			}
			&.selected a {
				&,
				&:active,
				&:visited {
					color: var(--text-highlight-color);
					border-color: var(--text-highlight-color);
					display: block;
					margin-top: -0.75rem;
				}
				&:hover span:after {
					border-bottom: 2px solid var(--text-highlight-color);
				}
				svg {
					fill: var(--text-color);
				}
			}
		}
	}

	@include mediaQuery('large-up') {
		left: 1rem;
	}

	@include mediaQuery('mobile-down') {
		z-index: 1;
		width: 75px;
		height: 75px;
		transition: all 0.5s ease-in-out;
		top: -1.5rem;
		left: -0.25rem;

		ul {
			position: absolute;
			margin: 0;
			top: 0;
			li {
				pointer-events: none;
				position: absolute;
				z-index: 1;
				top: 0;
				margin: 0;
				width: 75px;
				height: 65px;
				background-color: var(--background-color);
				border-radius: 0.5em;
				transition: top 0.5s ease-in-out;
				visibility: hidden;
				&.selected {
					z-index: 5;
					visibility: visible;
					a:hover span:after {
						content: '';
						border-bottom-color: transparent;
					}
				}
				a svg {
					transform: scale(0.7);
				}
			}
		}

		&.open {
			z-index: 3;
			background-color: var(--background-color);
			height: 240px;
			top: 0;
			@include standard-box-shadow;
			border-radius: 0.25em;
			ul li {
				pointer-events: all;
				visibility: visible;
				a svg {
					top: -0.25rem;
					padding: 0.5rem;
					border-radius: 50%;
					transform: scale(0.7);
					margin-top: 0;
				}
				span {
					opacity: 1;
				}
				&.home {
					top: 0;
				}
				&.work {
					top: 80px;
				}
				&.projects {
					top: 160px;
				}
				&.contact {
					top: 240px;
				}
			}
		}
	}
}
