@import './styles/global';

:root {
	color-scheme: light dark;
}

#root {
	height: 100%;
}

.App {
	--text-light-color: #585892;
	--text-light-highlight-color: #00007e;
	--background-light-color: #f9f9f9;
	--background-light-highlight-color: #eefbff;

	--text-dark-color: #acdbea;
	--text-dark-highlight-color: #eefbff;
	--background-dark-color: #303030;
	--background-dark-highlight-color: #101010;

	--text-color: var(--text-light-color);
	--text-highlight-color: var(--text-light-highlight-color);
	--background-color: var(--background-light-color);
	--background-highlight-color: var(--background-light-highlight-color);

	--header-height: 48px;

	&.dark-theme {
		--text-color: var(--text-dark-color);
		--text-highlight-color: var(--text-dark-highlight-color);
		--background-color: var(--background-dark-color);
		--background-highlight-color: var(--background-dark-highlight-color);
	}

	background-color: var(--background-color);
	color: var(--text-color);
	height: 100%;

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0;
		padding: 0;
		color: var(--text-highlight-color);
	}

	a,
	a:active,
	a:visited {
		color: var(--text-color);
		font-weight: bold;
	}

	main {
		position: relative;
		display: flex;
		top: var(--header-height);
		padding: 0 5em 1em 5em;
		background-color: inherit;
		@include mediaQuery('large-up') {
			padding: 0 7em 1em 7em;
		}
		@include mediaQuery('mobile-down') {
			padding: 0 1em 1em 1em;
		}
	}

	.card-container {
		position: relative;
		.anchor-position {
			pointer-events: none;
			position: absolute;
			top: -6rem;
			display: block;

			@include mediaQuery('mobile-down') {
				top: -10rem;
			}
		}
	}

	.timeline {
		.page-title {
			position: sticky;
			top: 2em;
			z-index: 1;
			width: 100%;
			background-color: var(--background-color);
			box-shadow: 0 0 0.35em 0.25em var(--background-color);
			font-size: 1.5em;
			line-height: 1em;
			margin: 0;
			padding: 0.25em 0;
		}
		.year,
		.company {
			position: sticky;
			position: -webkit-sticky;
			top: 2em;
			text-align: left;
			font-size: 1.5em;
			line-height: 1em;
			margin: 0;
			padding: 0.25em 0;
			background-color: var(--background-color);
			box-shadow: 0 0 0.35em 0.25em var(--background-color);
			white-space: nowrap;
		}
		.year {
			z-index: 2;
			margin: 1em 0 1em 4.5em;
		}
		.company {
			z-index: 3;
			margin: 1em 0 2em 7.5em;
			text-decoration: underline;
		}
		@include mediaQuery('mobile-down') {
			z-index: 5;
			.page-title,
			.year,
			.company {
				margin: 0;
				text-align: center;
				box-sizing: border-box;
				box-shadow: none;
			}
			.page-title {
				top: 2em;
			}
			.year {
				top: 3.5em;
				box-shadow: 0 0.25em 0.35em 0.25em var(--background-color);
			}
			.company {
				top: 5em;
				box-shadow: 0 0.25em 0.35em 0.25em var(--background-color);
			}
		}
	}

	hr {
		border-color: var(--text-highlight-color);
		margin: 4em auto;
		width: 66%;
	}

	.EyebrowInput {
		color: var(--text-color);
		background-color: var(--background-color);
		border-color: var(--text-highlight-color);
	}
}
