@use 'sass:map';

@mixin blank-button {
	appearance: none;
	border: none;
	background-color: transparent;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

@mixin standard-box-shadow {
	box-shadow: 2px 2px 12px 1px var(--text-color);
}

@mixin mediaQuery($size) {
	@media only screen and (map.get($MediaSizes, $size)) {
		@content;
	}
}

@mixin media-query {
	@content;
}

$MediaSizes: (
	'mobile-down': 'max-width: 678px',
	'large-up': 'min-width: 1000px',
);
