@import '../../styles/global.scss';
@import '../../styles/mixins.scss';

.MenuHeader {
	position: fixed;
	z-index: 100;
	width: 100%;
	background-color: var(--background-color);
	height: var(--header-height);

	.banner {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		h1 {
			text-align: center;
			flex-grow: 1;
		}
		.actions {
			position: absolute;
			top: 1rem;
			right: 2em;
			margin: 0.5em;
			button {
				padding: 0;
				color: var(--text-highlight-color);
				svg {
					transform: scale(1.5);
				}
			}
		}
	}

	@include mediaQuery('mobile-down') {
		.banner {
			h1 {
				margin: 0 1rem;
				font-size: 1.25em;
			}
			.actions {
				top: 0.5rem;
				right: 1rem;
			}
		}
	}
}
